<template>
  <!--PARA LOS BLUE DIAMOND -->
  <v-simple-table v-if="name_proveedor.toLocaleLowerCase().includes('blue-diamond')">
    <template v-slot:default>
      <tbody>
        <tr>
          <th
            rowspan="2"
            width="20%"
          >
            {{ listHotel.filter(e => e.id === item.hotel.id)[0].name }}
          </th>
          <th
            rowspan="2"
            width="10px"
          >
            <v-btn
              icon
              @click="showItem(index)"
            >
              <v-icon>
                {{ showRow && index === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
              </v-icon>
            </v-btn>
          </th>
          <th
            v-if="showRow && index === indexRow"
            rowspan="2"
          >
            {{ $t('lbl.temporadas') }}
          </th>
          <th rowspan="2"></th>
          <th v-if="showRow && index === indexRow">
            {{ $t('lbl.from') }}
          </th>
          <fragment v-if="showRow && index === indexRow">
            <th
              v-for="(from, indF) in item.seasons.from"
              :key="Math.random() * (indF + 1)"
              class="text-center"
            >
              {{ from }}
            </th>
          </fragment>
        </tr>
        <tr v-if="showRow && index === indexRow">
          <th>
            {{ $t('lbl.to') }}
          </th>
          <th
            v-for="(to, indF) in item.seasons.to"
            :key="Math.random() * (indF + 1)"
            class="text-center"
          >
            {{ to }}
          </th>
        </tr>
        <fragment v-if="showRow && index === indexRow">
          <!--RELEASE-->
          <tr>
            <td colspan="2"></td>
            <td>
              <b>RELEASE</b>
            </td>
            <td colspan="2"></td>
            <td
              v-for="(release, indRe) in item.release"
              :key="Math.random() * (indRe + 1)"
              class="text-center"
            >
              {{ release }}
            </td>
          </tr>

          <!--ZONAS-->
          <tr>
            <td><b>ZONA</b></td>
            <td><b>HABITACIONES</b></td>
            <td><b>OBSERVACIONES</b></td>
            <td><b>CUPOS</b></td>
            <td><b>PLAN</b></td>
            <td :colspan="item.release.length"></td>
          </tr>
          <tr
            v-for="(zona, indRe) in item.rooms_zona"
            :key="Math.random() * (indRe + 1)"
          >
            <td>{{ zona.zona }}</td>
            <td>
              <span v-if="zona.room.id">
                <span v-if="allRooms.filter(e => e.id === zona.room.id)[0].name.length > 10">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ allRooms.filter(e => e.id === zona.room.id)[0].name | truncate(10) }}
                      </span>
                    </template>
                    <span>{{ allRooms.filter(e => e.id === zona.room.id)[0].name }}</span>
                  </v-tooltip>
                  <!--<v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiInformationOutline }}
                      </v-icon>
                    </template>
                    <span>{{ allRooms.filter(e => e.id === zona.room.id)[0].name }}</span>
                  </v-tooltip>-->
                </span>
                <span v-else>
                  {{ allRooms.filter(e => e.id === zona.room.id)[0].name }}
                </span>
              </span>
            </td>
            <td>
              <v-tooltip
                v-if="zona.observaciones"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </template>
                <span>{{ zona.observaciones }}</span>
              </v-tooltip>
            </td>
            <td>{{ zona.cupo }}</td>
            <td>
              <span v-if="zona.plan.id">
                {{ allPlanes.filter(e => e.id === zona.plan.id)[0].siglas }}
              </span>
            </td>
            <td
              v-for="(price, indRe) in zona.prices"
              :key="Math.random() * (indRe + 1)"
              class="text-center"
            >
              {{ price | currency }}
            </td>
          </tr>

          <!--SUPLEMENTOS DE ALOJAMIENTO-->
          <tr>
            <td><b>SUPLEMENTOS DE ALOJAMIENTO</b></td>
            <td><b>HABITACIONES</b></td>
            <td><b>OBSERVACIONES</b></td>
            <td :colspan="item.release.length + 2"></td>
          </tr>
          <tr
            v-for="(suplemento, indRe) in item.suplementos_rooms"
            :key="Math.random() * (indRe + 1)"
          >
            <td>
              <span v-if="suplemento.suplemento.id">
                {{ allSuplementos.filter(e => e.id === suplemento.suplemento.id)[0].name }}
              </span>
            </td>
            <td>
              <span v-if="suplemento.room">
                <span v-if="suplemento.room.length >= 2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ allRooms.filter(e => e.id === suplemento.room[0].id)[0].name | truncate(10) }}
                      </span>
                    </template>
                    <span>
                      <span
                        v-for="(room, indR) in suplemento.room"
                        :key="Math.random() * (indR + 1)"
                      >
                        {{ allRooms.filter(e => e.id === room.id)[0].name }}<br />
                      </span>
                    </span>
                  </v-tooltip>
                </span>
                <span v-else>
                  <span v-if="allRooms.filter(e => e.id === suplemento.room[0].id)[0].name.length > 10">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          style="cursor: pointer;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ allRooms.filter(e => e.id === suplemento.room[0].id)[0].name | truncate(10) }}
                        </span>
                      </template>
                      <span>{{ allRooms.filter(e => e.id === suplemento.room[0].id)[0].name }}</span>
                    </v-tooltip>
                  </span>
                  <span v-else>
                    {{ allRooms.filter(e => e.id === suplemento.room[0].id)[0].name }}
                  </span>
                </span>
              </span>
            </td>
            <td>
              <v-tooltip
                v-if="suplemento.observaciones"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </template>
                <span>{{ suplemento.observaciones }}</span>
              </v-tooltip>
            </td>
            <td colspan="2"></td>
            <td
              v-for="(price, indR) in suplemento.prices"
              :key="Math.random() * (indR + 1)"
              class="text-center"
            >
              <span v-if="price.pc">{{ price.price }}%</span>
              <span v-else>{{ price.price | currency }}</span>
            </td>
          </tr>

          <!--REDUCCIONES-->
          <tr>
            <td><b>REDUCCIONES</b></td>
            <td><b>HABITACIONES</b></td>
            <td><b>OBSERVACIONES</b></td>
            <td :colspan="item.release.length + 2"></td>
          </tr>
          <tr
            v-for="(reduction, indRe) in item.reductions_rooms"
            :key="Math.random() * (indRe + 1)"
          >
            <td>
              <span v-if="reduction.reduccion.id">
                <span v-if="allReducciones.filter(e => e.id === reduction.reduccion.id)[0].name.length > 35">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ allReducciones.filter(e => e.id === reduction.reduccion.id)[0].name | truncate(35) }}
                      </span>
                    </template>
                    <span>{{ allReducciones.filter(e => e.id === reduction.reduccion.id)[0].name }}</span>
                  </v-tooltip>
                </span>
                <span v-else>
                  {{ allReducciones.filter(e => e.id === reduction.reduccion.id)[0].name }}
                </span>
              </span>
            </td>
            <td>
              <span v-if="reduction.room">
                <span v-if="reduction.room.length >= 2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ allRooms.filter(e => e.id === reduction.room[0].id)[0].name | truncate(10) }}
                      </span>
                    </template>
                    <span>
                      <span
                        v-for="(room, indR) in reduction.room"
                        :key="Math.random() * (indR + 1)"
                      >
                        {{ allRooms.filter(e => e.id === room.id)[0].name }}<br />
                      </span>
                    </span>
                  </v-tooltip>
                </span>
                <span v-else>
                  <span v-if="allRooms.filter(e => e.id === reduction.room[0].id)[0].name.length > 10">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          style="cursor: pointer;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ allRooms.filter(e => e.id === reduction.room[0].id)[0].name | truncate(10) }}
                        </span>
                      </template>
                      <span>{{ allRooms.filter(e => e.id === reduction.room[0].id)[0].name }}</span>
                    </v-tooltip>
                  </span>
                  <span v-else>
                    {{ allRooms.filter(e => e.id === reduction.room[0].id)[0].name }}
                  </span>
                </span>
              </span>
            </td>
            <td>
              <v-tooltip
                v-if="reduction.observaciones"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </template>
                <span>{{ reduction.observaciones }}</span>
              </v-tooltip>
            </td>
            <td colspan="2"></td>
            <td
              v-for="(pc, indR) in reduction.pc"
              :key="Math.random() * (indR + 1)"
              class="text-center"
            >
              <span>{{ pc }}%</span>
            </td>
          </tr>

          <!-- OTROS SUPLEMENTOS-->
          <tr>
            <td><b>OTROS SUPLEMENTOS</b></td>
            <td><b>HABITACIONES</b></td>
            <td><b>OBSERVACIONES</b></td>
            <td :colspan="item.release.length + 2"></td>
          </tr>
          <tr
            v-for="(suplemento, indRe) in item.other_suplementos_rooms"
            :key="Math.random() * (indRe + 1)"
          >
            <td>
              <span v-if="suplemento.suplemento.id">
                {{ allSuplementos.filter(e => e.id === suplemento.suplemento.id)[0].name }}
              </span>
            </td>
            <td>
              <span v-if="suplemento.room">
                <span v-if="suplemento.room.length >= 2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ allRooms.filter(e => e.id === suplemento.room[0].id)[0].name | truncate(10) }}
                      </span>
                    </template>
                    <span>
                      <span
                        v-for="(room, indR) in suplemento.room"
                        :key="Math.random() * (indR + 1)"
                      >
                        {{ allRooms.filter(e => e.id === room.id)[0].name }}<br />
                      </span>
                    </span>
                  </v-tooltip>
                </span>
                <span v-else>
                  <span v-if="allRooms.filter(e => e.id === suplemento.room[0].id)[0].name.length > 10">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          style="cursor: pointer;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ allRooms.filter(e => e.id === suplemento.room[0].id)[0].name | truncate(10) }}
                        </span>
                      </template>
                      <span>{{ allRooms.filter(e => e.id === suplemento.room[0].id)[0].name }}</span>
                    </v-tooltip>
                  </span>
                  <span v-else>
                    {{ allRooms.filter(e => e.id === suplemento.room[0].id)[0].name }}
                  </span>
                </span>
              </span>
            </td>
            <td>
              <v-tooltip
                v-if="suplemento.observaciones"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </template>
                <span>{{ suplemento.observaciones }}</span>
              </v-tooltip>
            </td>
            <td colspan="2"></td>
            <td
              v-for="(price, indR) in suplemento.prices"
              :key="Math.random() * (indR + 1)"
              class="text-center"
            >
              <span v-if="price.pc">{{ price.price }}%</span>
              <span v-else>{{ price.price | currency }}</span>
            </td>
          </tr>

          <!-- USOS-->
          <tr>
            <td><b>OCUPACIÓN MÁXIMA</b></td>
            <td><b>HABITACIONES</b></td>
            <td><b>OBSERVACIONES</b></td>
            <td :colspan="2"></td>
            <td><b>Max ADL</b></td>
            <td><b>Max MNR</b></td>
            <td><b>Max PAX</b></td>
          </tr>
          <tr
            v-for="(uso, indRe) in item.usos_rooms"
            :key="Math.random() * (indRe + 1)"
          >
            <td></td>
            <td>
              <span v-if="uso.room">
                <span v-if="uso.room.length >= 2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ allRooms.filter(e => e.id === uso.room[0].id)[0].name | truncate(10) }}
                      </span>
                    </template>
                    <span>
                      <span
                        v-for="(room, indR) in uso.room"
                        :key="Math.random() * (indR + 1)"
                      >
                        {{ allRooms.filter(e => e.id === room.id)[0].name }}<br />
                      </span>
                    </span>
                  </v-tooltip>
                </span>
                <span v-else>
                  <span v-if="allRooms.filter(e => e.id === uso.room[0].id)[0].name.length > 10">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          style="cursor: pointer;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ allRooms.filter(e => e.id === uso.room[0].id)[0].name | truncate(10) }}
                        </span>
                      </template>
                      <span>{{ allRooms.filter(e => e.id === uso.room[0].id)[0].name }}</span>
                    </v-tooltip>
                  </span>
                  <span v-else>
                    {{ allRooms.filter(e => e.id === uso.room[0].id)[0].name }}
                  </span>
                </span>
              </span>
            </td>
            <td>
              <v-tooltip
                v-if="uso.observaciones"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </template>
                <span>{{ uso.observaciones }}</span>
              </v-tooltip>
            </td>
            <td colspan="2"></td>
            <td class="text-center">
              {{ uso.max_adult }}
            </td>
            <td class="text-center">
              {{ uso.max_child }}
            </td>
            <td class="text-center">
              {{ uso.max_pax }}
            </td>
          </tr>

          <!-- CONTACTOS-->
          <tr>
            <td><b>CONTACTO</b></td>
            <td><b>EMAIL</b></td>
            <td><b>TEL</b></td>
            <td :colspan="item.release.length + 2"></td>
          </tr>
          <tr
            v-for="(contact, indRe) in item.contacts"
            :key="Math.random() * (indRe + 1)"
          >
            <td>{{ contact.name }}</td>
            <td>
              <span v-if="contact.emails">
                <span
                  v-for="(email, indR) in contact.emails"
                  :key="Math.random() * (indR + 1)"
                >
                  {{ email }}<br />
                </span>
              </span>
            </td>
            <td>
              <span v-if="contact.phones">
                <span
                  v-for="(phone, indR) in contact.phones"
                  :key="Math.random() * (indR + 1)"
                >
                  {{ phone }}<br />
                </span>
              </span>
            </td>
            <td :colspan="item.release.length + 3"></td>
          </tr>

          <!-- GENERAL-->
          <tr>
            <td>
              <b>GENERAL</b>
            </td>
            <td></td>
            <td :colspan="item.release.length + 4"></td>
          </tr>
          <tr
            v-for="(general, indRe) in item.general"
            :key="Math.random() * (indRe + 1)"
          >
            <td>
              {{ general.key }}
            </td>
            <td>{{ general.value }}</td>
            <td :colspan="item.release.length + 4"></td>
          </tr>

          <!-- DATOS BANCARIOS-->
          <tr>
            <td>
              <b>DATOS BANCARIOS</b>
            </td>
            <td></td>
            <td :colspan="item.release.length + 4"></td>
          </tr>
          <tr
            v-for="(datos_banca, indRe) in item.datos_bancarios"
            :key="Math.random() * (indRe + 1)"
          >
            <td>
              {{ datos_banca.key }}
            </td>
            <td>{{ datos_banca.value }}</td>
            <td :colspan="item.release.length + 4"></td>
          </tr>
        </fragment>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiPlaylistEdit,
  mdiCancel,
  mdiInformationOutline,
  mdiListStatus,
  mdiFileReplaceOutline,
  mdiLinkVariantPlus,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    name_proveedor: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    listHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allPlanes: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allSuplementos: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allReducciones: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      modificando: false,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiPlaylistEdit,
        mdiCancel,
        mdiInformationOutline,
        mdiListStatus,
        mdiFileReplaceOutline,
        mdiLinkVariantPlus,
        mdiMenuDown,
        mdiMenuRight,
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      showRow: false,
      indexRow: -1,
    }
  },
  computed: {
    ...mapState({
      addressHotel: state => state.app.addressHotel,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  methods: {
    ...mapMutations([
      'adicionarImgHotels',
      'updateImgHotels',
      'adicionarContactHotel',
      'updateContactHotel',
      'updateAddressHotel',
      'updateGeoTag',
      'setOnlyShow',
    ]),
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.8rem !important;
  height: 2rem !important;
}
</style>
